<template>
    <div>
        <breadcrumbs :items="breadcrumbs" add-link="/shipments/create" />

        <base-card class="my-4 mx-auto" :icon="$t('shipments.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline card-main-title">{{ shipment.code }}</div>

                    <div>
                        <!-- btn edit item  -->
                        <v-btn
                            fab
                            small
                            dark
                            :color="$store.state.app.color"
                            :to="`/shipments/update/${shipment.id}`"
                            v-if="shipment.state.id == 'waiting_accept'"
                        >
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>

                        <!-- btn print -->
                        <v-btn fab small color="blue-grey darken-3" dark class="ms-1" :to="`/shipments/print/${shipment.id}`" target="_blank">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>

                        <!-- btn delete item  -->
                        <v-btn fab small dark class="ms-1" color="error" @click="deleteDialog = true" v-if="shipment.state.id == 'waiting_accept'">
                            <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </div>

                <!-- Start Delete dialog -->
                <dialog-base type="DELETE" max-width="450px" v-model="deleteDialog" @canceled="deleteDialog = false" @confirmed="deleteItem">
                    <template #content>
                        <h3 class="title">Are you sure you want to delete it ?</h3>
                    </template>
                </dialog-base>
                <!-- End Delete dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <v-toolbar flat>
                    <v-tabs v-model="tabs" fixed-tabs>
                        <v-tab href="#shipment-info">{{ $t("attributes.shipment") }}</v-tab>
                        <v-tab href="#shipment-history">{{ $t("attributes.history") }}</v-tab>
                        <v-tab href="#shipment-comments">{{ $t("attributes.comments") }}</v-tab>
                    </v-tabs>
                </v-toolbar>

                <v-tabs-items v-model="tabs">
                    <!-- Start shipment info -->
                    <v-tab-item value="shipment-info">
                        <v-card flat>
                            <div class="px-5">
                                <v-row>
                                    <!-- Start Receiver data -->
                                    <v-col cols="12">
                                        <app-header :label="$t('attributes.receiver')" :prepend-inner-icon="$t('common.icons.receiver')"></app-header>
                                    </v-col>

                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field v-model.trim="shipment.receiver_name" :label="$t('attributes.name')" readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field v-model.trim="shipment.receiver_phone" :label="$t('attributes.phone')" readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field
                                            v-model.trim="shipment.receiver_phone2"
                                            :label="$t('attributes.phone2')"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field
                                            v-model.trim="shipment.receiver_address"
                                            :label="$t('attributes.address')"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <!-- End Receiver data -->

                                    <!-- Start other data -->
                                    <v-col cols="12">
                                        <app-header :label="$t('attributes.receiver')" :prepend-inner-icon="$t('common.icons.receiver')"></app-header>
                                    </v-col>

                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field v-model.trim="shipment.city.name" :label="$t('attributes.city')" readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field v-model.trim="shipment.branch.name" :label="$t('attributes.branch')" readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field v-model.trim="shipment.price" :label="$t('attributes.price')" readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field v-model.trim="shipment.fee" :label="$t('attributes.fee')" readonly></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="6" lg="3">
                                        <v-text-field
                                            v-model.trim="shipment.deliver_fee"
                                            :label="$t('attributes.deliver_fee')"
                                            readonly
                                        ></v-text-field>
                                    </v-col> -->
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field
                                            :label="$t('attributes.fee_on')"
                                            :value="$t(`attributes.${shipment.fee_on_customer ? 'sender' : 'receiver'}`)"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field v-model="shipment.received_at" :label="$t('attributes.received_at')" readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field v-model.trim="shipment.state.name" :label="$t('attributes.state')" readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3" v-if="shipment.trip_id">
                                        <v-text-field
                                            v-model="shipment.trip_id"
                                            :label="$t('attributes.trip')"
                                            readonly
                                            append-icon="mdi-information-outline"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3" v-if="shipment.adjustment_customer_id">
                                        <v-text-field
                                            v-model="shipment.adjustment_customer_id"
                                            :label="$t('attributes.adjustment_customer')"
                                            readonly
                                            append-icon="mdi-information-outline"
                                            @click:append="$router.push(`/adjustment-customers/${shipment.adjustment_customer_id}`)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model.trim="shipment.note"
                                            :label="$t('attributes.note')"
                                            rows="1"
                                            auto-grow
                                            readonly
                                        ></v-textarea>
                                    </v-col>
                                    <!-- End other data -->
                                </v-row>

                                <v-divider></v-divider>

                                <v-row>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field
                                            :value="shipment.created_at | datetimeFormat"
                                            :label="$t('attributes.created_at')"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-text-field
                                            :value="shipment.updated_at | datetimeFormat"
                                            :label="$t('attributes.updated_at')"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div class="mb-5 ">
                                <v-divider></v-divider>

                                <!-- Start shipment items -->
                                <app-header
                                    :label="$t('attributes.shipment_items')"
                                    :prepend-inner-icon="$t('shipments.icon')"
                                    class="px-5 py-3"
                                ></app-header>

                                <v-data-table
                                    :headers="headers"
                                    :items="shipment.items"
                                    hide-default-footer
                                    :items-per-page="100"
                                    class="elevation-1 mt-5"
                                >
                                    <template v-slot:item.product="{ item }">
                                        {{ (item.product && item.product.name) || "--------" }}
                                    </template>
                                </v-data-table>
                                <!-- End shipment items -->
                            </div>
                        </v-card>
                    </v-tab-item>
                    <!-- End shipment info -->

                    <!-- Start shipment history -->
                    <v-tab-item value="shipment-history">
                        <v-card flat>
                            <v-card-text class="py-0">
                                <v-row justify="center">
                                    <v-col cols="auto">
                                        <v-timeline style="width: calc(100% + 60px);">
                                            <v-timeline-item
                                                right
                                                color="info"
                                                small
                                                v-for="item in shipment.history"
                                                :key="item.id + item.created_at"
                                            >
                                                <template v-slot:opposite>
                                                    <strong>{{ item.created_at | datetimeFormat }}</strong>
                                                </template>
                                                <v-card class="elevation-2 time-line-card" color="info" dark>
                                                    <v-card-title class="py-2 px-4 subtitle-1">{{ item.state.name }}</v-card-title>
                                                    <v-card-text dark v-if="item.note">{{ item.note }}</v-card-text>
                                                </v-card>
                                            </v-timeline-item>
                                        </v-timeline>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- End shipment history -->

                    <!-- Start shipments comments -->
                    <v-tab-item value="shipment-comments">
                        <v-card flat>
                            <list-comments :shipment_id="shipment.id"></list-comments>
                        </v-card>
                    </v-tab-item>
                    <!-- End shipments comments -->
                </v-tabs-items>
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "@/store/index";
import ListComments from "@/views/dashboard/shipments/ListComments.vue";

export default {
    components: { ListComments },

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;
        Promise.all([store.dispatch("city/fetchCitiesShort")]).then(() => {
            store.dispatch("shipment/fetchShipment", id).then(() => {
                next();
            });
        });
    },

    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t("shipments.title"),
                    to: "/shipments",
                    exact: true,
                    disabled: false
                },
                {
                    text: this.$route.params.id,
                    disabled: true
                }
            ],
            tabs: null,
            deleteDialog: false, // for delete
            headers: [
                { text: "#", value: "index", width: "30" },
                { text: this.$t("attributes.product"), value: "product" },
                { text: this.$t("attributes.count"), value: "count" },
                { text: this.$t("attributes.desc"), value: "desc" }
            ]
        };
    },
    created() {
        this.shipment.items.map((e, index) => (e.index = index + 1));
        this.breadcrumbs[1].text = this.shipment.code;
    },
    computed: {
        ...mapState({
            shipment: state => state.shipment.shipment
        })
    },
    methods: {
        ...mapActions("shipment", ["deleteShipment"]),

        /**
         * Delete shipment form server (send request to server to delete item )
         */
        deleteItem() {
            this.$store.state.app.loading = true;
            this.deleteShipment(this.shipment.id)
                .then(() => this.$router.replace("/shipments"))
                .finally(() => (this.$store.state.app.loading = false));
        }
    }
};
</script>

<style lang="scss" scoped>
.time-line-card {
    max-width: 80%;
    display: inline-block;
    min-width: 175px;
    .v-card__text {
        border-top: 1px solid #a8e4ff;
        padding-top: 10px;
        color: #fff;
    }
}
</style>
