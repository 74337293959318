<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('adjustment_customers.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("adjustment_customers.title") }}</div>
                </div>
            </template>

            <template #content>
                <v-divider></v-divider>
                <!-- Start show headers options -->
                <div class="px-4">
                    <v-select v-model="selected_headers" :items="headers" :label="$t('options.show_columns')" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip filter>
                                <span>{{ item.text }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
                <!-- End show headers options -->

                <v-divider></v-divider>

                <!-- Start search panel -->
                <v-expansion-panels class="" focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="justify-start">
                            <h5 class="subtitle-1"><v-icon>mdi-magnify</v-icon> {{ $t("options.search") }}</h5>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <!-- Start search options -->
                            <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                                <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                                    <v-row align="center">
                                        <v-col cols="12" sm="6" lg="3" class="pb-0" v-for="(field, index) in searchFields" :key="index">
                                            <v-text-field
                                                v-model.trim="search[field]"
                                                :label="$t(`attributes.${field}`)"
                                                maxlength="32"
                                                clearable
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today"
                                                vid="date_from"
                                                :name="$t('search.date_from')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.date_from"
                                                    :label="$t('search.date_from')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today|after_or_equal:@date_from"
                                                vid="date_to"
                                                :name="$t('search.date_to')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.date_to"
                                                    :label="$t('search.date_to')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today"
                                                vid="created_at_from"
                                                :name="$t('search.created_at_from')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.created_at_from"
                                                    :label="$t('search.created_at_from')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today|after_or_equal:@created_at_from"
                                                vid="created_at_to"
                                                :name="$t('search.created_at_to')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.created_at_to"
                                                    :label="$t('search.created_at_to')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                            <v-btn :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                            <!-- End search options -->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <!-- End search panel -->

                <v-divider></v-divider>

                <v-data-table :headers="selected_headers" :items="data.data" :items-per-page="100" hide-default-footer class="elevation-1">
                    <template v-slot:item.state="{ item }">
                        <v-chip :color="item.state.color" dark>{{ item.state.name }}</v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div style="width: 40px">
                            <v-btn x-small color="info" class="me-1" fab :to="`/adjustment-customers/${item.id}`">
                                <v-icon>mdi-information-variant</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("adjustmentCustomer/fetchAll", { page, params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            formDialog: false,
            errors: {},
            search: Object.assign({}, this.$route.query),
            searchFields: ["id", "note"],
            selected_headers: [],
            headers: [
                { text: this.$t("attributes.id"), value: "id" },
                { text: this.$t("attributes.amount"), value: "amount" },
                { text: this.$t("attributes.date"), value: "date" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.updated_at"), value: "updated_at" },
                { text: this.$t("attributes.actions"), value: "actions" }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.adjustmentCustomer.adjustments
        })
    },

    created() {
        this.selected_headers = this.headers.filter(c => !["note", "extra", "updated_at"].includes(c.value));
    },

    methods: {
        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
