import Axios from "axios";

const base_url = "adjustment-customers";

export default {
    /**
     * Display a listing of the Adjustment Customers.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(page = 1, params = {}) {
        return Axios.get(`${base_url}?page=${page}`, { params });
    },

    /**
     * Show data for a given Adjustment.
     *
     * @param {int} id
     * @returns {Promise}
     */
    getById(id) {
        return Axios.get(`${base_url}/${id}`);
    }
};
