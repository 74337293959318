/**
 * This file to set routes for dashboard
 */

/**
 * Properties for these routes
 */
const meta = {
    requiresAuth: true /* route requires authentication */,
    layout: "dashboard-layout" /* route uses dashboard-layout */
};

const routes = [
    {
        path: "/Home",
        component: require(`@/views/dashboard/Home.vue`).default,
        meta,
        alias: "/"
    },
    {
        path: "/profile",
        component: require(`@/views/dashboard/Profile.vue`).default,
        meta
    },
    {
        path: "/shipments",
        component: require(`@/views/dashboard/shipments/Index.vue`).default,
        meta
    },
    {
        path: "/customer-products",
        component: require(`@/views/dashboard/Products.vue`).default,
        meta
    },
    {
        path: "/customer-delegates",
        component: require(`@/views/dashboard/Delegates.vue`).default,
        meta
    },
    {
        path: "/shipments/print-multi",
        component: require(`@/views/dashboard/shipments/PrintShipments.vue`).default,
        meta: { ...meta, layout: "paper-layout" }
    },
    {
        path: "/shipments/create",
        component: require(`@/views/dashboard/shipments/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/shipments/:id",
        component: require(`@/views/dashboard/shipments/Show.vue`).default,
        meta
    },
    {
        path: "/shipments/print/:id",
        component: require(`@/views/dashboard/shipments/Print.vue`).default,
        meta: { ...meta, layout: "paper-layout" }
    },
    {
        path: "/shipments/update/:id",
        component: require(`@/views/dashboard/shipments/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/cities",
        component: require(`@/views/dashboard/Cities.vue`).default,
        meta
    },
    /* {
        path: "/branches",
        component: require(`@/views/dashboard/Branches.vue`).default,
        meta
    }, */
    {
        path: "/adjustment-customers",
        component: require(`@/views/dashboard/adjustment_customers/Index.vue`).default,
        meta
    },
    {
        path: "/adjustment-customers/print/:id",
        component: require(`@/views/dashboard/adjustment_customers/Print.vue`).default,
        meta: { ...meta, layout: "paper-layout" }
    },
    {
        path: "/adjustment-customers/:id",
        component: require(`@/views/dashboard/adjustment_customers/Show.vue`).default,
        meta
    }
];

export default routes;
