<template>
    <v-container class="fill-height" fluid>
        <v-flex>
            <v-card class="mt-4 mx-auto" width="350px">
                <v-sheet class="v-sheet--offset mx-auto pa-4 text-center" dark color="info" elevation="12" max-width="calc(100% - 32px)">
                    <v-icon size="125px">mdi-account-circle</v-icon>
                    <h3>{{ $t("auth.login") }}</h3>
                </v-sheet>

                <v-card-text class="pt-0 pb-10">
                    <validation-observer v-slot="{ handleSubmit }">
                        <v-form class="px-3" @submit.prevent="handleSubmit(signIn)">
                            <!-- show errors if there is errors  -->
                            <has-errors :errors="errors"></has-errors>

                            <validation-provider rules="required|phone" :name="$t('attributes.phone')" v-slot="{ errors }">
                                <v-text-field
                                    prepend-icon="mdi-account"
                                    v-model.trim="user.phone"
                                    :label="$t('attributes.phone')"
                                    :error-messages="errors"
                                    :maxlength="10"
                                    required
                                ></v-text-field>
                            </validation-provider>

                            <validation-provider rules="required|min:6|max:32" :name="$t('attributes.password')" v-slot="{ errors }">
                                <password-field
                                    v-model.trim="user.password"
                                    :label="$t('attributes.password')"
                                    :error-messages="errors"
                                    :max-length="32"
                                >
                                </password-field>
                            </validation-provider>

                            <v-btn type="submit" color="info" class="mt-4" block>{{ $t("auth.login") }}</v-btn>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Login",

    data: () => ({
        showPassword: false,
        errors: {}, // validation errors for add/update request
        user: { username: "", password: "" } // to save temp data to use it in add,update and delete
    }),

    methods: {
        ...mapActions("auth", ["login", "logout"]),

        /**
         * Add or update data in server depends on isUpdate property
         */
        signIn() {
            this.$store.state.app.loading = true;

            this.errors = {};

            this.login(this.user)
                .then(() => {
                    this.$router.push("/home");
                })
                .catch(r => {
                    window.r = r;
                    this.errors = r.response && r.response.data && r.response.data.errors;
                })
                .finally(() => {
                    this.$store.state.app.loading = false;
                });
        }
    }
};
</script>
