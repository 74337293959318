<template>
    <div>
        <!-- Start list of comments -->
        <v-list three-line class="pb-0">
            <template>
                <v-list-item
                    v-for="item in comments"
                    :key="`${item.id}`"
                    :class="`list-comments comment-${item.commenter} ${!item.seen && item.commenter != 'customer' ? 'comment-unseen' : ''}`"
                >
                    <v-list-item-avatar>
                        <v-icon large>mdi-account</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <h6 class="text-subtitle-1 font-weight-bold">{{ item.creator.name }}</h6>
                            <div class="text-caption mt-n1">
                                <v-icon small>mdi-clock-outline</v-icon>
                                {{ item.created_at }}
                            </div>
                        </v-list-item-title>

                        <v-list-item-subtitle class="text--primary mt-2">{{ item.comment }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <!-- End list of comments -->

        <v-divider></v-divider>

        <!-- Start add comment -->
        <validation-observer ref="commentsForm" v-slot="{ handleSubmit }">
            <v-form class="px-3" @submit.prevent="handleSubmit(addComment)">
                <!-- show errors if there is errors when add/update item -->
                <has-errors :errors="errors"></has-errors>

                <v-row class="pt-5">
                    <!-- Start Sender data -->
                    <v-col cols="12">
                        <validation-provider rules="required|min:3|max:500" :name="$t('attributes.comment')" v-slot="{ errors }">
                            <v-textarea
                                v-model.trim="comment"
                                :label="$t('attributes.comment')"
                                :placeholder="$t('hints.add_comment')"
                                :error-messages="errors"
                                counter
                                :maxlength="500"
                                rows="2"
                                filled
                                clearable
                                auto-grow
                                @blur="resetCommentField"
                                @click:clear="resetCommentField"
                            ></v-textarea>
                        </validation-provider>
                    </v-col>
                </v-row>

                <div class="text-end px-5 py-3">
                    <v-btn color="info" min-width="150px" type="submit">{{ $t("options.save") }}</v-btn>
                </div>
            </v-form>
        </validation-observer>
        <!-- End add comment -->
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "ListComments",
    props: {
        shipment_id: { type: Number, required: true }
    },

    data() {
        return {
            comment_id: null,
            comment: null,
            errors: {}
        };
    },

    computed: {
        comments() {
            return this.$store.state.shipment.comments;
        }
    },

    methods: {
        ...mapActions("shipment", ["createComment"]),

        resetCommentField() {
            !this.comment && this.$refs.commentsForm.reset();
        },

        addComment() {
            this.$store.state.app.loading = true;
            this.errors = {};

            this.createComment({
                shipment_id: this.shipment_id,
                comment: this.comment
            })
                .then(r => {
                    this.$emit("added");
                    this.comment = null;
                    this.$refs.commentsForm.reset();
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        }
    }
};
</script>

<style scoped lang="scss">
.list-comments:not(:last-of-type) {
    border-bottom: 1px solid #0000001c;
}

.list-comments {
    &.comment-unseen {
        background-color: #1867c01f !important;
    }
    &.comment-user,
    &.comment-deliver,
    &.comment-customer {
        border-left-width: 4px;
        border-left-style: solid;
    }
    &.comment-user {
        border-left-color: #2196f3;
    }
    &.comment-deliver {
        border-left-color: #37474f;
    }
    &.comment-customer {
        border-left-color: #f44336;
    }
}

body.rtl {
    .list-comments {
        &.comment-user,
        &.comment-deliver,
        &.comment-customer {
            border-left: none;
            border-right: 4px;
            border-right-style: solid;
        }
        &.comment-user {
            border-right-color: #2196f3;
        }
        &.comment-deliver {
            border-right-color: #37474f;
        }
        &.comment-customer {
            border-right-color: #f44336;
        }
    }
}
</style>
