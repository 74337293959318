import cityApi from "@/apis/cityApi";

/**
 * Add short city object
 * This function it returns only properties that shortCitites needs them
 * @returns {object} city
 */
function shortCity(city) {
    return {
        id: city.id,
        name: city.name,
        price: city.price,
        active: city.active
    };
}

export default {
    namespaced: true,

    state: {
        cities: [],
        citiesShort: []
    },

    getters: {
        getById: state => id => {
            return state.citiesShort.length && state.citiesShort.find(item => item.id === id);
        }
    },

    mutations: {
        SET_CITIES(state, cities) {
            state.cities = cities;
        },
        SET_CITIES_SHORT(state, cities) {
            state.citiesShort = cities;
        }
    },

    actions: {
        fetchCities({ commit }, { page = 1, params = {} }) {
            return cityApi.getAll(page, params).then(r => {
                commit("SET_CITIES", r.data);
            });
        },

        fetchCitiesShort({ commit, state }) {
            if (!state.citiesShort || !state.citiesShort.length) {
                return cityApi.getAllShort().then(r => {
                    commit("SET_CITIES_SHORT", r.data.data);
                });
            }
        }
    }
};
