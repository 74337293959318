<template>
    <div>
        <div class="title-box">
            <h4 class="invoice-title">فاتورة شحنة</h4>
        </div>

        <!-- Start shipment info -->
        <v-row>
            <v-col cols="auto">
                <div class="invoice-sub-title">
                    اسم المرسل: <bdi>{{ user.name }}</bdi>
                </div>
                <div class="invoice-sub-title">
                    رقم المرسل: <bdi>{{ user.phone }}</bdi>
                </div>
                <div class="invoice-sub-title">
                    كود الشحنة : <bdi>{{ shipment.code }}</bdi>
                </div>
                <div class="invoice-sub-title">
                    تاريخ الاستلام : <bdi>{{ shipment.received_at }}</bdi>
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <div class="invoice-sub-title">
                    اسم المستلم : <bdi>{{ shipment.receiver_name }}</bdi>
                </div>
                <div class="invoice-sub-title">
                    رقم المستلم: <bdi>{{ shipment.receiver_phone }}</bdi>
                </div>
                <div class="invoice-sub-title">
                    المدينة: <bdi>{{ shipment.city.name }}</bdi>
                </div>
                <div class="invoice-sub-title">
                    الفرع: <bdi>{{ shipment.branch.name }}</bdi>
                </div>
            </v-col>
        </v-row>
        <!-- End shipment info -->

        <!-- Start items -->
        <v-simple-table class="table-info">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>الشحنة</th>
                        <th>العدد</th>
                        <th>وصف</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in shipment.items" :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                            <bdi>{{ (item.product && item.product.name) || "--------" }}</bdi>
                        </td>
                        <td>
                            <bdi class="no-wrap">{{ item.count }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ item.desc }}</bdi>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <!-- End items -->

        <!-- Start summery -->
        <v-simple-table class="table-info">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>التاريخ</th>
                        <th>العدد</th>
                        <th>القيمة</th>
                        <th>القيمة مع الشحن</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <bdi>{{ statistics.date }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ statistics.count }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ shipment.price }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ shipment.price + (shipment.fee_on_customer ? 0 : shipment.fee) }}</bdi>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <!-- End summery -->
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let id = routeTo.params.id;
    Promise.all([store.dispatch("city/fetchCitiesShort")]).then(() => {
        store.dispatch("shipment/fetchShipment", id).then(() => {
            next();
        });
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    computed: {
        ...mapState({
            shipment: state => state.shipment.shipment,
            user: state => state.auth.user
        }),

        statistics() {
            return {
                count: this.shipment.items.length,
                cost: 0, //this.shipments.reduce((sum, s) => sum + s.cost, 0),
                date: _getCurrentDateTime()
            };
        }
    },

    mounted() {
        setTimeout(() => {
            window.onafterprint = window.close;
            print();
        }, 1000);
    }
};
</script>

<style lang="scss" scoped>
.invoice-sub-title {
    font-family: "Cairo", sans-serif !important;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 12px;
    bdi {
        font-weight: normal;
    }
}
body {
    direction: rtl;
    font-family: "Cairo", sans-serif !important;
    table,
    th,
    tr,
    td {
        font-family: "Cairo", sans-serif !important;
        border: thin solid rgba(0, 0, 0, 0.5);
        border-collapse: collapse;
        text-align: center;
        padding: 8px 5px !important;
        height: auto !important;
        font-size: 12px !important;
    }
    .title-box {
        text-align: center;
    }
    .invoice-title {
        font-family: "Cairo", sans-serif !important;
        padding: 5px;
        display: inline-block;
        color: #333;
        border-bottom: 1px solid;
    }
    .table-info {
        margin-top: 20px;
        th {
            background-color: #ddd;
        }
    }
    .no-wrap {
        white-space: nowrap;
    }
}
</style>
