import Axios from "axios";

const base_url = "cities";

export default {
    /**
     * Display a listing of the Cities.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(page = 1, params = {}) {
        return Axios.get(base_url + "?page=" + page, { params });
    },

    /**
     * Get all cities with specific properties of cities.
     *
     * @returns {Promise}
     */
    getAllShort() {
        return Axios.get(`${base_url}/short`);
    }
};
