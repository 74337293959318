import shipmentApi from "@/apis/shipmentApi";
import i18n from "@/plugins/i18n.js";

function formatData(shipment, getters) {
    shipment.state = getters.getStateById(shipment.state);
    shipment.history && shipment.history.map(s => (s.state = getters.getStateById(s.state)));
    shipment.code = shipment.city && _getShipmentCode(shipment);
    return shipment;
}

export default {
    namespaced: true,

    state: {
        shipments: [],
        shipment: {},
        comments: [],
        statuses: [
            { id: "missing", name: i18n.t("attributes.shipment_statuses.missing"), color: "red accent-4", colorCode: "#d50000" },
            { id: "waiting_accept", name: i18n.t("attributes.shipment_statuses.waiting_accept"), color: "yellow accent-4", colorCode: "#ffd600" },
            { id: "accepted", name: i18n.t("attributes.shipment_statuses.accepted"), color: "blue-grey darken-3", colorCode: "#37474f" },
            { id: "in_stock", name: i18n.t("attributes.shipment_statuses.in_stock"), color: "blue-grey darken-3", colorCode: "#37474f" },
            {
                id: "prepare_to_deliver",
                name: i18n.t("attributes.shipment_statuses.prepare_to_deliver"),
                color: "blue-grey darken-3",
                colorCode: "#37474f"
            },
            { id: "on_the_way", name: i18n.t("attributes.shipment_statuses.on_the_way"), color: "orange", colorCode: "#ff9800" },
            { id: "unable_to_deliver", name: i18n.t("attributes.shipment_statuses.unable_to_deliver"), color: "brown" },
            { id: "returned_to_stock", name: i18n.t("attributes.shipment_statuses.returned_to_stock"), color: "red", colorCode: "#f44336" },
            {
                id: "returned_to_customer",
                name: i18n.t("attributes.shipment_statuses.returned_to_customer"),
                color: "deep-orange",
                colorCode: "#ff5722"
            },
            { id: "deliverd", name: i18n.t("attributes.shipment_statuses.deliverd"), color: "green", colorCode: "#4caf50" },
            { id: "completed", name: i18n.t("attributes.shipment_statuses.completed"), color: "info", colorCode: "#2196F3" },
            { id: "closed", name: i18n.t("attributes.shipment_statuses.closed"), color: "primary", colorCode: "#1976D2" }
        ]
    },

    getters: {
        getById: state => id => {
            return state.shipments.data && state.shipments.data.find(item => item.id === id);
        },
        getStateById: state => id => state.statuses.find(item => item.id === id)
    },

    mutations: {
        ADD_SHIPMENT(state, shipment) {
            if (state.shipments.data) {
                state.shipments.data.unshift(shipment);
                state.shipments.meta.to++;
                state.shipments.meta.total++;
            }
        },
        ADD_COMMENT(state, comment) {
            if (!state.comments) {
                state.comments = [];
            }
            state.comments.push(comment);
        },
        UPDATE_SHIPMENT(state, shipment) {
            if (state.shipments.data) {
                state.shipments.data = [...state.shipments.data.map(c => (c.id !== shipment.id ? c : shipment))];
            }
        },
        SET_SHIPMENTS(state, shipments) {
            state.shipments = shipments;
        },
        SET_SHIPMENT(state, shipment) {
            state.shipment = shipment;
            state.comments = shipment.comments;
        },
        DELETE_SHIPMENT(state, id) {
            if (state.shipments.data) {
                state.shipments.data = state.shipments.data.filter(item => item.id !== id);
                state.shipments.meta.to--;
                state.shipments.meta.total--;
            }
        }
    },

    actions: {
        addShipment({ commit, getters }, shipment) {
            return shipmentApi.create(shipment).then(r => {
                Toast.success("Added successfully");
                commit("ADD_SHIPMENT", formatData(r.data.data, getters));
                return Promise.resolve(r);
            });
        },

        createComment({ state, commit }, id) {
            return shipmentApi.createComment(id || state.shipment.id).then(r => {
                commit("ADD_COMMENT", r.data.data);
            });
        },

        fetchShipments({ commit, getters }, { page = 1, params = {} }) {
            return shipmentApi.getAll(page, params).then(r => {
                r.data.data.map(s => formatData(s, getters));
                commit("SET_SHIPMENTS", r.data);
            });
        },

        fetchShipment({ commit, getters }, id) {
            return shipmentApi.getById(id).then(r => {
                commit("SET_SHIPMENT", formatData(r.data.data, getters));
            });
        },

        updateShipment({ commit, getters }, shipment) {
            return shipmentApi.update(shipment).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_SHIPMENT", formatData(r.data.data, getters));
                return Promise.resolve(r);
            });
        },

        deleteShipment({ commit }, id) {
            return shipmentApi.delete(id).then(r => {
                Toast.success("Successfully deleted");
                commit("DELETE_SHIPMENT", id);
            });
        }
    }
};
