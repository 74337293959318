<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('cities.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("cities.title") }}</div>
                </div>
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="search.code" :label="$t('attributes.code')" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="headers"
                    :items="data.data"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="rowClass"
                >
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("city/fetchCities", { page, params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            formDialog: false,
            city: {},
            errors: {},
            search: Object.assign({}, this.$route.query),
            headers: [
                { text: this.$t("attributes.name"), value: "name" },
                { text: this.$t("attributes.code"), value: "code" },
                { text: this.$t("attributes.fee"), value: "fee" },
                { text: this.$t("attributes.note"), value: "note" }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.city.cities
        })
    },

    methods: {
        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
