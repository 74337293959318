import i18n from "@/plugins/i18n.js";

/**
 * This module for app settings
 */
export default {
    namespaced: true,

    state: {
        layouts: {
            dashboard: {
                sidebar: {
                    show: true,
                    links: [
                        { label: i18n.t("home.title"), icon: i18n.t("home.icon"), url: "/home" },
                        { label: i18n.t("profile.title"), icon: i18n.t("profile.icon"), url: "/profile" },
                        { label: i18n.t("shipments.title"), icon: i18n.t("shipments.icon"), url: "/shipments" },
                        { label: i18n.t("adjustment_customers.title"), icon: i18n.t("adjustment_customers.icon"), url: "/adjustment-customers" },
                        //{ label: i18n.t("branches.title"), icon: i18n.t("branches.icon"), url: "/branches" },
                        { label: i18n.t("customers.products.title"), icon: i18n.t("customers.products.icon"), url: "/customer-products" },
                        { label: i18n.t("customers.delegates.title"), icon: i18n.t("customers.delegates.icon"), url: "/customer-delegates" },
                        { label: i18n.t("cities.title"), icon: i18n.t("cities.icon"), url: "/cities" }
                    ]
                }
            }
        },

        color: "info",
        dark: false,
        loading: true,
        rtl: true
    },

    actions: {
        changeLanguage({ state }, lang) {
            state.lang = this.$app.$i18n.changeLanguageTo(lang);
            state.rtl = this.$app.$i18n.t("direction") === "rtl";
            this.$app.$vuetify.rtl = state.rtl;
        }
    }
};
