<template>
    <div>
        <v-overlay :value="loading" z-index="9999999">
            <v-progress-circular indeterminate size="128"></v-progress-circular>
        </v-overlay>
        <component :is="layout"> </component>
    </div>
</template>

<script>
import DashboardLayout from "./views/dashboard/layouts/Dashboard.vue";
import EmptyLayout from "./views/layouts/Empty.vue";
import PaperLayout from "./views/layouts/Paper.vue";
import { mapState } from "vuex";

const default_layout = "empty-layout";

export default {
    name: "App",

    components: {
        DashboardLayout,
        EmptyLayout,
        PaperLayout
    },

    computed: {
        ...mapState("app", ["loading"]),

        layout() {
            return this.$route.meta.layout || default_layout;
        }
    }
};
</script>

<style lang="scss">
body.rtl {
    direction: rtl;
    font-family: "Cairo", sans-serif !important;
    .v-application,
    .v-application .headline,
    .v-application .subtitle-1,
    .v-application .text-h2,
    .v-application .text-h3,
    .v-application .text-h4,
    .v-application .text-h5,
    .v-application .text-h6,
    .v-application .caption,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-family: "Cairo", sans-serif !important;
    }
    .v-btn {
        letter-spacing: 0;
    }
    .v-text-field input {
        line-height: 22px;
    }
}
.swal2-popup.swal2-toast {
    min-width: 360px;
    justify-content: flex-start !important;
}
.card-main-title {
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.65) !important;
}
</style>
