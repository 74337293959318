var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('shipments.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("shipments.title")))]),_c('div',[_c('v-btn',{attrs:{"fab":"","small":"","color":_vm.$store.state.app.color,"to":"/shipments/create"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),(_vm.shipments_print.length)?_c('v-btn',{staticClass:"ms-1",attrs:{"fab":"","small":"","color":"blue-grey darken-3","dark":"","to":{ path: '/shipments/print-multi', query: { shipments_print: _vm.shipments_print } },"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e()],1)])]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('div',{staticClass:"px-4"},[_c('v-select',{attrs:{"items":_vm.headers,"label":_vm.$t('options.show_columns'),"multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"filter":""}},[_c('span',[_vm._v(_vm._s(item.text))])])]}}]),model:{value:(_vm.selected_headers),callback:function ($$v) {_vm.selected_headers=$$v},expression:"selected_headers"}})],1),_c('v-divider'),_c('v-expansion-panels',{attrs:{"focusable":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"justify-start"},[_c('h5',{staticClass:"subtitle-1"},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" "+_vm._s(_vm.$t("options.search")))],1)]),_c('v-expansion-panel-content',[_c('validation-observer',{ref:"searchForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.filterData)}}},[_c('v-row',{attrs:{"align":"center"}},[_vm._l((_vm.searchFields),function(field,index){return _c('v-col',{key:index,staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t(("attributes." + field)),"maxlength":"32","clearable":""},model:{value:(_vm.search[field]),callback:function ($$v) {_vm.$set(_vm.search, field, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search[field]"}})],1)}),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"select_object","name":_vm.$t('attributes.city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cities-field',{attrs:{"clearable":"","error-messages":errors},model:{value:(_vm.search.city),callback:function ($$v) {_vm.$set(_vm.search, "city", $$v)},expression:"search.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('shipment-statuses-field',{attrs:{"clearable":""},model:{value:(_vm.search.state),callback:function ($$v) {_vm.$set(_vm.search, "state", $$v)},expression:"search.state"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"date|before_or_equal:today","vid":"received_at_from","name":_vm.$t('search.received_at_from')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('search.received_at_from'),"error-messages":errors,"clearable":""},model:{value:(_vm.search.received_at_from),callback:function ($$v) {_vm.$set(_vm.search, "received_at_from", $$v)},expression:"search.received_at_from"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"date|before_or_equal:today|after_or_equal:@received_at_from","vid":"received_at_to","name":_vm.$t('search.received_at_to')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('search.received_at_to'),"error-messages":errors,"clearable":""},model:{value:(_vm.search.received_at_to),callback:function ($$v) {_vm.$set(_vm.search, "received_at_to", $$v)},expression:"search.received_at_to"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"date|before_or_equal:today","vid":"created_at_from","name":_vm.$t('search.created_at_from')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('search.created_at_from'),"error-messages":errors,"clearable":""},model:{value:(_vm.search.created_at_from),callback:function ($$v) {_vm.$set(_vm.search, "created_at_from", $$v)},expression:"search.created_at_from"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"date|before_or_equal:today|after_or_equal:@created_at_from","vid":"created_at_to","name":_vm.$t('search.created_at_to')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('search.created_at_to'),"error-messages":errors,"clearable":""},model:{value:(_vm.search.created_at_to),callback:function ($$v) {_vm.$set(_vm.search, "created_at_to", $$v)},expression:"search.created_at_to"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"auto"}},[_c('v-btn',{attrs:{"color":_vm.$store.state.app.color,"type":"submit"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],2)],1)]}}])})],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.selected_headers,"items":_vm.data.data,"items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"font-weight-bold",attrs:{"label":("" + (item.code)),"value":item.id},model:{value:(_vm.shipments_print),callback:function ($$v) {_vm.shipments_print=$$v},expression:"shipments_print"}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.state.color,"dark":""}},[_vm._v(_vm._s(item.state.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"80px"}},[_c('v-btn',{staticClass:"me-1",attrs:{"x-small":"","color":"info","fab":"","to":("/shipments/" + (item.id))}},[_c('v-icon',[_vm._v("mdi-information-variant")])],1),(item.state.id == 'waiting_accept')?_c('v-btn',{attrs:{"x-small":"","fab":"","color":"info","dark":"","to":("/shipments/update/" + (item.id))}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}}])}),_c('pagination',{attrs:{"data":_vm.data.meta || {}},on:{"page-changed":_vm.filterData}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }