<template>
    <!-- Start sidebar -->
    <v-navigation-drawer v-model="sidebar.show" app :right="$store.state.app.rtl">
        <v-list class="sidebar-customize" dense nav>
            <!-- Start user section  -->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon large>mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- End user section  -->

            <v-divider class="mb-2"></v-divider>

            <!-- Start print links  -->
            <template v-for="(link, index) in sidebar.links">
                <v-list-group
                    v-if="link.group"
                    :key="`link${link.label}${index}`"
                    :prepend-icon="link.icon"
                    :color="$store.state.app.color + ' darken-3'"
                    no-action
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>{{ link.label }}</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <list-item
                        v-for="(subLink, subIndex) in link.links"
                        :key="`link${subLink.label}${subIndex}`"
                        :to="subLink.url"
                        :active-class="$store.state.app.color"
                        :icon="subLink.icon"
                        :label="subLink.label"
                    ></list-item>
                </v-list-group>

                <list-item
                    v-else
                    :key="`link${link.label}`"
                    :to="link.url"
                    :active-class="$store.state.app.color"
                    :icon="link.icon"
                    :label="link.label"
                ></list-item>
            </template>
            <!-- End print links  -->

            <!-- Start logout section  -->
            <v-list-item link @click="logout">
                <v-list-item-action>
                    <v-icon>{{ $t("auth.logout.icon") }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t("auth.logout.title") }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- End logout section  -->
        </v-list>
    </v-navigation-drawer>
    <!--/ End sidebar -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import ListItem from "@/components/layouts/dashboard/ListItem";

export default {
    name: "Sidebar",

    components: { ListItem },

    computed: {
        ...mapState("app", {
            sidebar: state => state.layouts.dashboard.sidebar
        }),

        ...mapState("auth", {
            user: state => state.user
        })
    },

    methods: {
        ...mapActions("auth", ["logout"])
    }
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr {
    .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
        padding-left: 25px;
    }
}
.v-application--is-rtl {
    .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
        padding-right: 25px;
    }
}
</style>

<style lang="scss">
/* This customize for elements that (scoped property) dose not change them */
.v-application--is-ltr .sidebar-customize {
    .v-list-item__action:first-child,
    .v-list-item__icon:first-child {
        margin-right: 25px;
    }
}
.v-application--is-rtl .sidebar-customize {
    .v-list-item__action:first-child,
    .v-list-item__icon:first-child {
        margin-left: 25px;
    }
}
</style>
