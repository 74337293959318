import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import city from "@/store/modules/city";
import customerDelegate from "@/store/modules/customerDelegate";
import customerProduct from "@/store/modules/customerProduct";
import shipment from "@/store/modules/shipment";
import adjustmentCustomer from "@/store/modules/adjustmentCustomer";
import auth from "@/store/modules/auth";
import statistic from "@/store/modules/statistic";
import branch from "@/store/modules/branch";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        city,
        customerDelegate,
        customerProduct,
        shipment,
        auth,
        adjustmentCustomer,
        statistic,
        branch
    }
});
