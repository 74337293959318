var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('customers.products.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("customers.products.title")))]),_c('v-btn',{attrs:{"fab":"","small":"","color":_vm.$store.state.app.color},on:{"click":function($event){$event.stopPropagation();return _vm.prepareToAddItem($event)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('dialog-base',{attrs:{"type":(_vm.product.id && 'UPDATE') || 'ADD',"max-width":"450"},on:{"canceled":_vm.formDialogClose,"confirmed":_vm.validateAndSave},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"productsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateAndSave)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:32","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"product.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:100","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":100,"rows":"1","auto-grow":""},model:{value:(_vm.product.note),callback:function ($$v) {_vm.$set(_vm.product, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"product.note"}})]}}],null,true)})],1)],1),_c('button',{staticClass:"hide",attrs:{"type":"submit"}})],1)]}}])})]},proxy:true}]),model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"searchForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.filterData)}}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"clearable":""},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.name"}})],1),_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"auto"}},[_c('v-btn',{staticClass:"me-2",attrs:{"color":_vm.$store.state.app.color,"type":"submit"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1)]}}])}),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data.data,"items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"40px"}},[_c('v-btn',{attrs:{"x-small":"","fab":"","color":"info","dark":""},on:{"click":function($event){return _vm.prepareToUpdateItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}])}),_c('pagination',{attrs:{"data":_vm.data.meta || {}},on:{"page-changed":_vm.filterData}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }