import adjustmentApi from "@/apis/adjustmentCustomerApi";

export default {
    namespaced: true,

    state: {
        adjustments: [],
        adjustment: {}
    },

    mutations: {
        SET_ITEMS(state, adjustments) {
            state.adjustments = adjustments;
        },
        SET_ITEM(state, adjustment) {
            state.adjustment = adjustment;
        }
    },

    actions: {
        fetchAll({ commit }, { page = 1, params = {} }) {
            return adjustmentApi.getAll(page, params).then(r => {
                commit("SET_ITEMS", r.data);
            });
        },

        fetchById({ commit }, id) {
            return adjustmentApi.getById(id).then(r => {
                commit("SET_ITEM", r.data.data);
                return Promise.resolve(r);
            });
        }
    }
};
