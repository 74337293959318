<script>
import { Doughnut } from "vue-chartjs";

export default {
    name: "ChartDoughnut",
    extends: Doughnut,
    props: {
        data: { required: true, type: Object },
        options: {
            type: Object,
            default: function() {
                return {
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        position: "right",
                        rtl: true,
                        labels: {
                            fontSize: 16,
                            padding: 12,
                            fontFamily: "Tajawal"
                        }
                    }
                };
            }
        }
    },
    mounted() {
        this.renderChart(this.data, this.options);
    },
    watch: {
        data() {
            this.renderChart(this.data, this.options);
        }
    }
};
</script>
