// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBvqXYGCf_RrGEb2bzQzg6aiE-RhK0CFz4",
    authDomain: "sella-338923.firebaseapp.com",
    projectId: "sella-338923",
    storageBucket: "sella-338923.appspot.com",
    messagingSenderId: "158439710212",
    appId: "1:158439710212:web:b40430e4ee13e6c5351f51",
    measurementId: "G-NN001QP050"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

getToken(messaging, { vapidKey: "BDi1tB-RWqVEbLH9BNfvBDu1RudolbvXdHc24PHy_bROccaelDB-emRi5ij1qqXbO52gckl4fLu7O1ghtnlmImM" })
    .then(currentToken => {
        console.log(currentToken);
        if (currentToken) {
            window.__fcm_token = currentToken;
            // Send the token to your server and update the UI if necessary
            // ...
        } else {
            // Show permission request UI
            console.error("No registration token available. Request permission to generate one.");
            // ...
        }
    })
    .catch(err => {
        console.error("An error occurred while retrieving token. ", err);
        // ...
    });

onMessage(messaging, payload => {
    console.log("Message received. ", payload);
    // ...
});

export default messaging;
