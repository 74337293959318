<template>
    <div>
        <div class="title-box">
            <h4 class="invoice-title">فاتورة شحنات</h4>
        </div>
        <v-simple-table class="table-info">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>#</th>
                        <!-- <th>{{ $t("attributes.code") }}</th>
                        <th>{{ $t("attributes.sender") }}</th>
                        <th>{{ $t("attributes.receiver_phone") }}</th>
                        <th>{{ $t("attributes.city") }}</th>
                        <th>{{ $t("attributes.price") }}</th>
                        <th>{{ $t("attributes.price_with_shipping") }}</th> -->
                        <th>الرمز</th>
                        <th>اسم المستلم</th>
                        <th>رقم المستلم</th>
                        <th>المدينة</th>
                        <th>القيمة</th>
                        <th>القيمة مع الشحن</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in shipments" :key="item.id">
                        <td>
                            <bdi>{{ index + 1 }}</bdi>
                        </td>
                        <td>
                            <bdi class="no-wrap">{{ item.id }}</bdi>
                        </td>
                        <td>
                            <bdi class="no-wrap">{{ item.receiver_name }}</bdi>
                        </td>
                        <td>
                            <bdi class="no-wrap">{{ item.receiver_phone }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ item.city.name }}</bdi>
                        </td>
                        <td>
                            <bdi class="no-wrap">{{ item.price }}</bdi>
                        </td>
                        <td>
                            <bdi class="no-wrap">{{ item.cost_with_shipping }}</bdi>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-simple-table class="table-info">
            <template v-slot:default>
                <thead>
                    <tr>
                        <!-- <th>{{ $t("attributes.employee") }}</th>
                        <th>{{ $t("attributes.date") }}</th>
                        <th>{{ $t("attributes.count") }}</th>
                        <th>{{ $t("attributes.price") }}</th>
                        <th>{{ $t("attributes.price_with_shipping") }}</th> -->
                        <th>التاريخ</th>
                        <th>العدد</th>
                        <th>الإجمالي</th>
                        <th>الإجمالي مع الشحن</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <bdi>{{ statistics.date }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ statistics.count }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ statistics.price }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ statistics.cost_with_shipping }}</bdi>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;
    params = { ids: (Array.isArray(params.shipments_print) && params.shipments_print) || [params.shipments_print] };

    store.dispatch("shipment/fetchShipments", { page, params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            shipments_print: []
        };
    },

    computed: {
        ...mapState({
            data: state => state.shipment.shipments.data,
            user: state => state.auth.user
        }),

        shipments() {
            this.data.map(s => {
                if (s.fee_on_customer) {
                    s.cost_with_shipping = s.price;
                } else {
                    s.cost_with_shipping = s.price + s.fee;
                }

                s.id = _getShipmentCode(s);
            });
            return this.data;
        },

        statistics() {
            return {
                count: this.shipments.length,
                price: this.shipments.reduce((sum, s) => sum + s.price, 0),
                cost_with_shipping: this.shipments.reduce((sum, s) => sum + s.cost_with_shipping, 0),
                date: _getCurrentDateTime()
            };
        }
    },

    mounted() {
        setTimeout(() => {
            window.onafterprint = window.close;
            print();
        }, 1000);
    }
};
</script>

<style lang="scss" scoped>
body {
    direction: rtl;
    font-family: "Cairo", sans-serif !important;
    table,
    th,
    tr,
    td {
        font-family: "Cairo", sans-serif !important;
        border: thin solid rgba(0, 0, 0, 0.5);
        border-collapse: collapse;
        text-align: center;
        padding: 8px 5px !important;
        height: auto !important;
        font-size: 12px !important;
    }
    .title-box {
        text-align: center;
    }
    .invoice-title {
        padding: 5px;
        display: inline-block;
        color: #333;
        border-bottom: 1px solid;
    }
    .table-info {
        margin-top: 20px;
        th {
            background-color: #ddd;
        }
    }
    .no-wrap {
        white-space: nowrap;
    }
}
</style>
