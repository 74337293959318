<template>
    <v-combobox
        :items="cities || []"
        item-text="name"
        :value="value"
        :label="label || $t('attributes.city')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
    ></v-combobox>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "CitiesField",

    props: {
        value: [String, Array, Object],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false }
    },

    computed: {
        ...mapState({
            cities: state => state.city.citiesShort
        })
    },
    created() {
        this.$store.dispatch("city/fetchCitiesShort");
    }
};
</script>
