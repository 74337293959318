<template>
    <div>
        <!-- Start statistics -->
        <v-row>
            <!-- Start available balance -->
            <v-col class="pt-6" cols="12" md="4">
                <base-card class="" :icon="$t('home.charts.shipments.icon')">
                    <template #title>
                        <div class="d-flex justify-space-between">
                            <div class="headline">الرصيد</div>
                        </div>
                    </template>

                    <template #content>
                        <v-divider class="mt-n3"></v-divider>
                        <div class="text-center">
                            <h4 class="text-h5 mt-5">{{ $t("home.statistics.available_balance.title") }}</h4>
                            <h1 class="text-h3 py-10 info--text">
                                <bdi>${{ Number(aggregates.available_balance).toLocaleString() }}</bdi>
                            </h1>

                            <v-divider></v-divider>

                            <h4 class="subtitle-1 py-4">
                                {{ $t("home.statistics.closed_balance.title") }}
                                <bdi class="font-weight-bold">$ {{ Number(aggregates.closed_balance).toLocaleString() }}</bdi>
                            </h4>
                        </div>
                    </template>
                </base-card>
            </v-col>
            <!-- End available balance -->
            <v-col cols="12" md="8">
                <v-row>
                    <v-col cols="12" sm="6" v-for="(item, index) in counts" :key="`counts${index}`">
                        <card-stats
                            :count="item.count"
                            :icon="item.icon"
                            :icon-to="item.iconTo"
                            :title="item.title"
                            :description="item.desc"
                        ></card-stats>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- End statistics -->

        <!-- Start charts -->
        <v-row>
            <v-col lg="5">
                <base-card class="mt-10 mx-auto" :icon="$t('home.charts.shipments.icon')" icon-to="/shipments">
                    <template #title>
                        <div class="d-flex justify-space-between">
                            <div class="headline">{{ $t("home.charts.shipments.title") }}</div>
                        </div>
                    </template>

                    <template #content>
                        <v-divider></v-divider>

                        <div class="pa-5">
                            <chart-doughnut :data="pieData"></chart-doughnut>
                        </div>
                    </template>
                </base-card>
            </v-col>

            <v-col lg="7">
                <base-card class="mt-10 mx-auto" :icon="$t('home.charts.adjustments.icon')" icon-to="/adjustment-customers">
                    <template #title>
                        <div class="d-flex justify-space-between">
                            <div class="headline">{{ $t("home.charts.adjustments.title") }}</div>
                        </div>
                    </template>

                    <template #content>
                        <v-divider></v-divider>

                        <div class="pa-5">
                            <chart-bar :data="adjustmentChart"></chart-bar>
                        </div>
                    </template>
                </base-card>
            </v-col>
        </v-row>
        <!-- End charts -->
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CardStats from "@/components/global/CardStats";
import ChartBar from "@/components/charts/Bar";
import ChartDoughnut from "@/components/charts/Doughnut";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(next) {
    store.dispatch("statistic/fetchData").then(() => next());
}

export default {
    name: "Home",

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(next);
    },

    components: { CardStats, ChartBar, ChartDoughnut },

    computed: {
        ...mapState("statistic", ["statistics", "aggregates", "charts"]),
        ...mapGetters("shipment", ["getStateById"]),

        getState: function() {
            return id => this.getStateById(id);
        },

        pieData() {
            let shipment_pie_data = { labels: [], datasets: [{ data: [], backgroundColor: [] }] };

            /* filter shipments to hide returned_to_customer shipments */
            let shipments = this.statistics.shipments.filter(e => e.state != "returned_to_customer");

            shipments.forEach(element => {
                shipment_pie_data.labels.push(this.$t(`attributes.shipment_statuses.${element.state}`));
                shipment_pie_data.datasets[0].data.push(element.count);
                shipment_pie_data.datasets[0].backgroundColor.push(this.getState(element.state).colorCode);
            });
            return shipment_pie_data;
        },

        adjustmentChart() {
            let chart = {
                labels: [],
                datasets: [
                    { label: this.$t("attributes.amount"), backgroundColor: "#359EE5", borderColor: "#359EE5", data: [], fill: false }
                    //      { label: this.$t("attributes.count"), backgroundColor: "#f87979", borderColor: "#f87979", data: [], fill: false }
                ]
            };

            chart.labels = this.charts.adjustments.map(e => `${e.year}-${e.month}`);
            chart.datasets[0].data = this.charts.adjustments.map(e => e.sum);
            //chart.datasets[1].data = this.charts.adjustments.map(e => e.count);

            return chart;
        },

        counts() {
            return [
                {
                    icon: this.$t("home.statistics.shipments.icon"),
                    iconTo: "/shipments?state=deliverd",
                    title: this.$t("home.statistics.shipments_deliverd.title"),
                    desc: this.$t("home.statistics.shipments_deliverd.desc"),
                    count: this.aggregates.shipments_deliverd
                },
                {
                    icon: this.$t("home.statistics.shipments.icon"),
                    iconTo: "/shipments?state=on_the_way",
                    title: this.$t("home.statistics.shipments_on_the_way.title"),
                    desc: this.$t("home.statistics.shipments_on_the_way.desc"),
                    count: this.aggregates.shipments_on_the_way
                },
                {
                    icon: this.$t("home.statistics.shipments.icon"),
                    iconTo: "/shipments?state=returned_to_stock",
                    title: this.$t("home.statistics.shipments_returned_to_stock.title"),
                    desc: this.$t("home.statistics.shipments_returned_to_stock.desc"),
                    count: this.aggregates.shipments_returned_to_stock
                },
                /* {
                    icon: this.$t("home.statistics.shipments.icon"),
                    title: this.$t("home.statistics.shipments_returned_to_customer.title"),
                    desc: this.$t("home.statistics.shipments_returned_to_customer.desc"),
                    count: this.aggregates.shipments_returned_to_customer
                }, */
                {
                    icon: this.$t("home.statistics.shipments_completed.icon"),
                    iconTo: "/shipments?state=completed",
                    title: this.$t("home.statistics.shipments_completed.title"),
                    desc: this.$t("home.statistics.shipments_completed.desc"),
                    count: this.aggregates.shipments_completed
                }
            ];
        }
    }
};
</script>
