import productsApi from "@/apis/customerProductApi";

/**
 * Add short product object
 * This function it returns only properties that shortCitites needs them
 * @returns {object} product
 */
function shortProduct(product) {
    return {
        id: product.id,
        name: product.name,
        note: product.note
    };
}

export default {
    namespaced: true,

    state: {
        products: [],
        productsShort: []
    },

    getters: {
        getById: state => id => {
            return state.products.data && state.products.data.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_PRODUCT(state, product) {
            if (state.products.data) {
                state.products.data.unshift(product);
                state.products.meta.to++;
                state.products.meta.total++;
            } // else {
            //    state.products = {
            //        data: [product],
            //        meta: { to: 1, total: 1 }
            //    };
            //}
            state.productsShort && state.productsShort.length && state.productsShort.push(shortProduct(product));
        },
        UPDATE_PRODUCT(state, product) {
            state.products.data = [...state.products.data.map(e => (e.id !== product.id ? e : product))];
            state.productsShort = [...state.productsShort.map(e => (e.id !== product.id ? e : shortProduct(product)))];
        },
        SET_PRODUCTS(state, products) {
            state.products = products;
        },
        SET_PRODUCTS_SHORT(state, products) {
            state.productsShort = products;
        },
        DELETE_PRODUCT(state, id) {
            if (state.products.data) {
                state.products.data = state.products.data.filter(item => item.id !== id);
                state.products.meta.to--;
                state.products.meta.total--;
            }
            state.productsShort = state.productsShort.filter(item => item.id !== id);
        }
    },

    actions: {
        addProduct({ commit }, data) {
            return productsApi.create(data).then(r => {
                Toast.success("Added successfully");
                commit("ADD_PRODUCT", r.data.data);
            });
        },

        fetchProducts({ commit }, { page = 1, params = {} }) {
            return productsApi.getAll(page, params).then(r => {
                commit("SET_PRODUCTS", r.data);
            });
        },

        fetchProduct({ commit }, id) {
            return productsApi.getById(id).then(r => {
                commit("SET_PRODUCTS", r.data.data);
            });
        },

        fetchProductsShort({ commit, state }) {
            if (!state.productsShort || !state.productsShort.length) {
                return productsApi.getAllShort().then(r => {
                    commit("SET_PRODUCTS_SHORT", r.data.data);
                });
            }
        },

        updateProduct({ commit }, product) {
            return productsApi.update(product).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_PRODUCT", r.data.data);
            });
        },

        deleteProduct({ commit }, id) {
            return productsApi.delete(id).then(r => {
                Toast.success("Successfully deleted");
                commit("DELETE_PRODUCT", id);
            });
        }
    }
};
